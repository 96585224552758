const FileData = {
  games: [
    {
      hoverString: "www.neopets.com",
      src: "images/icons/neopetsicon.png",
      url: "https://www.neopets.com/",
      x: 20,
      y: 30,
      iconText: "neopets.png",
    },
    {
      hoverString: "https://jstris.jezevec10.com/",
      url: "https://jstris.jezevec10.com/u/magicunicorn",
      src: "images/icons/tetris2.png",
      x: 20,
      y: 20,
      iconText: "tetris.png",
    },
    {
      hoverString: "The Company of Myself",
      src: "images/icons/com.png",
      url: "https://www.newgrounds.com/portal/view/518729",
      x: 40,
      y: 30,
      iconText: "company of.png",
    },
    {
      hoverString: "You Find Yourself In A Room",
      src: "images/icons/YFYIAR.png",
      url: "https://www.newgrounds.com/portal/view/556644",
      x: 54,
      y: 46,
      iconText: "yfyiar.png",
    },
    {
      hoverString: "Town of Salem",
      src: "images/icons/salem.png",
      url: "https://blankmediagames.com/",
      x: 75,
      y: 60,
      iconText: "salem.png",
    },
    {
      hoverString: "( Kingdom of Loathing )",
      src: "images/icons/kol.png",
      url: "https://www.kingdomofloathing.com/",
      x: 31,
      y: 25,
      iconText: "koloathing.png",
    },
    {
      hoverString: "( Spider and Web )",
      src: "images/icons/spiderweb.png",
      url: "https://eblong.com/zarf/zweb/tangle/",
      x: 18,
      y: 9,
      iconText: "spider and web.png",
    },
    {
      hoverString: "",
      src: "",
      url: "",
      x: 105,
      y: 100,
      iconText: ".",
    },
    {
      hoverString: "Club Penguin",
      src: "images/icons/cp.png",
      url: "https://www.cplegacy.com/",
      x: 50,
      y: 40,
      iconText: "cp.png",
    },
    {
      hoverString: "Shift",
      src: "images/icons/shift.png",
      url: "https://armorgames.com/play/751/shift",
      x: 7,
      y: 13,
      iconText: "shift.png",
    },
    {
      hoverString: "Fantage",
      src: "images/icons/fantage.png",
      url: "https://en.wikipedia.org/wiki/Fantage",
      x: 60,
      y: 60,
      iconText: "fantage.png",
    },
    {
      hoverString: "Loved",
      src: "images/icons/loved.png",
      url: "https://ocias.com/works/loved/",
      x: 42,
      y: 22,
      iconText: "loved.png",
    },
    {
      hoverString: "Cause of Death",
      src: "images/icons/cod.png",
      url: "https://tvtropes.org/pmwiki/pmwiki.php/VisualNovel/CauseOfDeath",
      x: 39,
      y: 65,
      iconText: "cause of death.png",
    },
    {
      hoverString: "( Dreaming Mary )",
      src: "images/icons/dreamingmary.png",
      url: "https://tvtropes.org/pmwiki/pmwiki.php/VideoGame/DreamingMary",
      x: 10,
      y: 19,
      iconText: "dreaming mary.png",
    },
    {
      hoverString: "( Okami )",
      src: "images/icons/okami.png",
      url: "https://en.wikipedia.org/wiki/%C5%8Ckami",
      x: 17,
      y: 35,
      iconText: "okami.png",
    },
    {
      hoverString: "( a dark room )",
      src: "images/icons/darkroom.png",
      url: "https://adarkroom.doublespeakgames.com/",
      x: 5,
      y: 34,
      iconText: "a dark room.png",
    },
    {
      hoverString: "windows93",
      src: "images/icons/windows93.png",
      url: "https://www.windows93.net/",
      x: 30,
      y: 60,
      iconText: "windows93.png",
    },
    {
      hoverString: "The Wiki Game",
      src: "images/icons/wikigame.png",
      url: "https://www.wikigame.com/",
      x: 28,
      y: 57,
      iconText: "wikigame.png",
    },
    {
      hoverString: "E.B.O.N.Y.",
      src: "images/icons/ebony.png",
      url: "https://ebonyriddle.com/",
      x: 28,
      y: 57,
      iconText: "ebony.png",
    },
  ],
  fandoms: [
    {
      hoverString: "TRShady",
      src: "images/icons/trshady.png",
      url: "http://forum.trshady.com/",
      x: 30,
      y: 60,
      iconText: "trshady.png",
    },
    {
      hoverString: "goodreads",
      src: "images/icons/goodreads.png",
      url: "https://www.goodreads.com/mellyeliu",
      x: 30,
      y: 60,
      iconText: "goodreads.png",
    },
    {
      hoverString: "Life is Strange",
      src: "images/icons/lis.png",
      url: "https://archiveofourown.org/works/4577451",
      x: 75,
      y: 25,
      iconText: "life is strange.png",
    },
    {
      hoverString: "www.tvtropes.org",
      src: "images/icons/tvtropes.png",
      url: "https://www.tvtropes.org/",
      x: 25,
      y: 27,
      iconText: "tvtropes.png",
    },
    {
      hoverString: "Deism, Reincarnation, and Creation Myths",
      src: "images/icons/deism.png",
      url: "https://en.wikipedia.org/wiki/Pandeism",
      x: 76,
      y: 17,
      iconText: "deism.webloc",
    },
    {
      hoverString: "tyler x josh (21p)",
      src: "images/icons/21p.png",
      url: "https://archiveofourown.org/works/8262935",
      x: 25,
      y: 38,
      iconText: "21p.png",
    },
    {
      hoverString: "everlark ♡",
      src: "images/icons/everlark.png",
      url: "https://archiveofourown.org/works/603428",
      x: 23,
      y: 65,
      iconText: "everlark.png",
    },
    {
      hoverString: "azula x mai (atla) ♡",
      src: "images/icons/maizula.png",
      url: "https://archiveofourown.org/works/4249794/chapters/9617619",
      x: 42,
      y: 48,
      iconText: "tymaizula.png",
    },
    {
      hoverString: "joseon-era kdramas",
      src: "images/icons/kdrama.png",
      url: "https://archiveofourown.org/works/4249794/chapters/9617619",
      x: 50,
      y: 45,
      iconText: "dae jang geum.png",
    },
  ],
  tools: [
    {
      hoverString: "are.na",
      src: "images/icons/arena.png",
      url: "https://www.are.na/",
      x: 45,
      y: 53,
      iconText: "are.na.png",
    },
    {
      hoverString: "z library",
      src: "images/icons/zlib.png",
      url: "https://reddit.com/zlib",
      x: 35,
      y: 53,
      iconText: "z-lib.png",
    },
    {
      hoverString: "ms paint",
      src: "images/icons/paint.png",
      url: "https://jspaint.app/",
      x: 35,
      y: 53,
      iconText: "ms paint.png",
    },
    {
      hoverString: "esoteric.codes",
      src: "images/icons/esoteric.png",
      url: "https://www.newgrounds.com/portal/view/518729",
      x: 51,
      y: 34,
      iconText: "esoteric codes.png",
    },
    {
      hoverString: "( TCRF )",
      src: "images/icons/tcrf.png",
      url: "https://tcrf.net/The_Cutting_Room_Floor",
      x: 40,
      y: 59,
      iconText: "tcrf.png",
    },
    {
      hoverString: "( daily deviation dream )",
      src: "images/icons/deviantart.png",
      url: "https://www.deviantart.com/",
      x: 71,
      y: 72,
      iconText: "deviantart.png",
    },
    {
      hoverString: "( MSCHF )",
      src: "images/icons/mschf.png",
      url: "https://www.mschf.com/",
      x: 37,
      y: 32,
      iconText: "mschf.png",
    },
    {
      hoverString: "Rational Wiki",
      src: "images/icons/rational.png",
      url: "https://rationalwiki.org/wiki/Special:Random",
      x: 76,
      y: 68,
      iconText: "rational wiki.png",
    },
    {
      hoverString: "( scp foundation )",
      src: "images/icons/scp.png",
      url: "https://scp-wiki.wikidot.com/",
      x: 50,
      y: 72,
      iconText: "scp.png",
    },
    {
      hoverString: "( Edit Wars )",
      src: "images/icons/wiki.png",
      url: "https://en.wikipedia.org/wiki/User:TheUnmissingPiece",
      x: 58,
      y: 65,
      iconText: "wiki.jpeg",
    },
  ],
  "About Me": [
    {
      hoverString: "૮꒰ ˶• ༝ •˶꒱ა ♡",
      src: "images/windows/bio.png",
      border: true,
      x: 68,
      y: 22.5,
    },
    {
      hoverString: "૮꒰ ˶• ༝ •˶꒱ა ♡",
      src: "images/me/mememe.png",
      url: "https://vsco.co/mellyeliu/gallery",
      x: 80,
      y: 42,
      iconText: "meee.png",
    },
    {
      hoverString: "૮꒰ ˶• ༝ •˶꒱ა ♡",
      src: "images/me/meme.png",
      url: "https://vsco.co/mellyeliu/gallery",
      x: 83,
      y: 26,
      iconText: "mee.png",
    },
    {
      hoverString: "૮꒰ ˶• ༝ •˶꒱ა ♡",
      src: "images/me/me.png",
      url: "https://vsco.co/mellyeliu/gallery",
      x: 60,
      y: 35,
      iconText: "meeee.png",
    },
    {
      hoverString: "( Girlhood )",
      src: "images/windows/emojis.png",
      border: true,
      x: 59,
      y: 14,
    },
    {
      hoverString: "( Socials )",
      src: "images/windows/socials.png",
      border: true,
      x: 9,
      y: 28,
    },
    {
      hoverString: "( 🌐🤍🎀🫧 )",
      src: "images/windows/internet.png",
      border: true,
      x: 17,
      y: 45,
    },
  ],
  Obsessions: [],
};

export default FileData;
